<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Mark Sheet
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <transition
                  v-if="
                    status === 1 &&
                      form.items.data.length > 1 &&
                      $auth.can('marks-generate-bulk') &&
                      $auth.loggedInRole().slug === 'administrator' &&
                      selected.length > 0
                  "
                  name="animate-css-transition"
                  enter-active-class="animated fadeInRight"
                  leave-active-class="animated fadeOutRight"
                >
                  <v-btn
                    v-on="on"
                    title="Annual Mark Sheet Download"
                    fab
                    dark
                    small
                    color="warning"
                    @click="SMSModalshow"
                  >
                    <v-icon>sms</v-icon>
                  </v-btn>
                </transition>
              </template>
              <span>Send SMS</span>
            </v-tooltip>
            <v-tooltip v-if="markSheetType !== 'rating'" bottom>
              <template v-slot:activator="{ on }">
                <transition
                  v-if="
                    status === 1 &&
                      form.items.data.length > 1 &&
                      $auth.can('marks-generate-bulk') &&
                      selectedExam.is_final === 1
                  "
                  name="animate-css-transition"
                  enter-active-class="animated fadeInRight"
                  leave-active-class="animated fadeOutRight"
                >
                  <v-btn
                    v-on="on"
                    title="Annual Mark Sheet Download"
                    fab
                    dark
                    small
                    color="warning"
                    @click="
                      (issueDateDialog = true),
                        (issueDate = today),
                        (printType = 'annual'),
                        (singlePrintEnable = false)
                    "
                  >
                    <v-icon>insert_drive_file</v-icon>
                  </v-btn>
                </transition>
              </template>
              <span>Generate Annual (Merged) Grade Sheet</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <transition
                  v-if="
                    status === 1 &&
                      form.items.data.length > 1 &&
                      $auth.can('marks-generate-bulk')
                  "
                  name="animate-css-transition"
                  enter-active-class="animated fadeInRight"
                  leave-active-class="animated fadeOutRight"
                >
                  <v-btn
                    v-on="on"
                    title="Print Marksheet"
                    fab
                    dark
                    small
                    color="primary"
                    @click="
                      (issueDateDialog = true),
                        (issueDate = today),
                        (printType = 'terminal'),
                        (singlePrintEnable = false)
                    "
                  >
                    <v-icon>insert_drive_file</v-icon>
                  </v-btn>
                </transition>
              </template>
              <span>Generate Terminal Grade Sheet</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <transition
                  v-if="
                    status === 1 &&
                      form.items.data.length > 1 &&
                      $auth.can('marks-ledger-generate-bulk') &&
                      selectedExam.is_final === 1 &&
                      markSheetType !== 'rating'
                  "
                  name="animate-css-transition"
                  enter-active-class="animated fadeInRight"
                  leave-active-class="animated fadeOutRight"
                >
                  <v-btn
                    v-on="on"
                    title="Download Ledger"
                    fab
                    dark
                    small
                    @click="
                      (printType = 'annual'),
                        printLedger(true),
                        (singlePrintEnable = false)
                    "
                    color="warning"
                  >
                    <v-icon>grid_on</v-icon>
                  </v-btn>
                </transition>
              </template>
              <span>Generate Annual (Merged) Ledger</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <transition
                  v-if="
                    status === 1 &&
                      form.items.data.length > 1 &&
                      $auth.can('marks-ledger-generate-bulk') &&
                      markSheetType !== 'rating'
                  "
                  name="animate-css-transition"
                  enter-active-class="animated fadeInRight"
                  leave-active-class="animated fadeOutRight"
                >
                  <v-btn
                    v-on="on"
                    title="Download Ledger"
                    fab
                    dark
                    small
                    @click="
                      (printType = 'terminal'),
                        printLedger(true),
                        (singlePrintEnable = false)
                    "
                    color="green"
                  >
                    <v-icon>grid_on</v-icon>
                  </v-btn>
                </transition>
              </template>
              <span>Generate Ledger</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <transition
                  v-if="
                    status === 1 &&
                      form.items.data.length > 1 &&
                      $auth.can('marks-ledger-generate-bulk') &&
                      selectedExam.is_final === 1 &&
                      markSheetType !== 'rating'
                  "
                  name="animate-css-transition"
                  enter-active-class="animated fadeInRight"
                  leave-active-class="animated fadeOutRight"
                >
                  <v-btn
                    v-on="on"
                    title="Ledger Preview"
                    fab
                    dark
                    small
                    @click="
                      (printType = 'annual'),
                        printLedger(false),
                        (singlePrintEnable = false)
                    "
                    color="warning"
                  >
                    <v-icon>crop_free</v-icon>
                  </v-btn>
                </transition>
              </template>
              <span>Preview Annual (Merged) Ledger</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <transition
                  v-if="
                    status === 1 &&
                      form.items.data.length > 1 &&
                      $auth.can('marks-ledger-generate-bulk') &&
                      markSheetType !== 'rating'
                  "
                  name="animate-css-transition"
                  enter-active-class="animated fadeInRight"
                  leave-active-class="animated fadeOutRight"
                >
                  <v-btn
                    v-on="on"
                    title="Ledger Preview"
                    fab
                    dark
                    small
                    @click="
                      (printType = 'terminal'),
                        printLedger(false),
                        (singlePrintEnable = false)
                    "
                    color="red"
                  >
                    <v-icon>crop_free</v-icon>
                  </v-btn>
                </transition>
              </template>
              <span>Preview Ledger</span>
            </v-tooltip>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs12>
                <v-select
                  :loading="examLoading"
                  :disabeld="!exams.length"
                  :items="exams"
                  class="pa-0"
                  label="Exam"
                  v-model="exam"
                  outlined
                  dense
                />
              </v-flex>
            </v-card-title>
          </v-card>
          <v-container
            style="margin-top: -12px;
    margin-bottom: -24px;"
            v-if="status === 0 && form.items.data.length"
          >
            <strong style="color:#f95050;font-size: 14px;">
              <v-icon color="#f95050" style="font-size: 17px">warning</v-icon>
              Marks entry still pending for :
              <span v-if="verfied.length > 0"
                ><span
                  class="subject-error"
                  v-for="(x, key) in verfied"
                  :key="key"
                  >{{ x }}</span
                ></span
              >
              <span v-if="academic.length > 0"
                ><span
                  class="subject-error"
                  v-for="(y, key) in academic"
                  :key="key"
                  >{{ y }}</span
                ></span
              >
            </strong>
          </v-container>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :hide-default-footer="
              form.items.data.length < 1 ||
                pagination.totalItems < pagination.rowsPerPage
            "
            :items="form.items.data"
            :loading="form.loading"
            :options.sync="pagination"
            :footer-props="footerProps"
            class="marks_entry"
          >
            <template v-slot:headers="props">
              <tr>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  :class="[
                    header.sortable ? 'column sortable' : '',
                    pagination.descending ? 'desc' : 'asc',
                    header.align === 'left' ? 'text-xs-left' : '',
                    ,
                    header.align === 'right' ? 'text-xs-right' : '',
                  ]"
                >
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <template v-slot:item="{ index, item }">
              <tr :active="selected" @click="selected = !selected">
                <td class="text-xs-left">{{ item.roll }}</td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-left">
                  <span v-if="markSheetType !== 'rating'">
                    {{ item.remark ? item.remark.remarks : "" }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </td>
                <td class="text-xs-right">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }" v-if="isECA()">
                      <v-btn
                        v-on="on"
                        small
                        icon
                        color="pink"
                        class="pa-0 ma-1"
                        @click="printECA(item.enroll_id)"
                      >
                        <v-icon small dark color="white"
                          >local_printshop</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Generate ECA Report</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        v-if="
                          status === 1 &&
                            $auth.can('marks-generate-bulk') &&
                            selectedExam.is_final === 1 &&
                            markSheetType !== 'rating'
                        "
                        small
                        icon
                        color="warning"
                        class="pa-0 ma-1"
                        @click="printSingleModal(item.enroll_id, 'annual')"
                      >
                        <v-icon small dark>print</v-icon>
                      </v-btn>
                    </template>
                    <span>Generate Annual (Merged) Mark Sheet</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        v-if="status === 1 && $auth.can('marks-generate-bulk')"
                        small
                        icon
                        color="primary"
                        class="pa-0 ma-1"
                        @click="printSingleModal(item.enroll_id, 'terminal')"
                      >
                        <v-icon small dark>print</v-icon>
                      </v-btn>
                    </template>
                    <span>Generate Terminal Sheet</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        v-if="
                          status === 1 &&
                            $auth.can('marks-generate-bulk') &&
                            ($auth.user().class_teacher ||
                              myRoleSlug === 'super' ||
                              myRoleSlug === 'administrator') &&
                            markSheetType !== 'rating'
                        "
                        small
                        icon
                        color="success"
                        class="pa-0 ma-1"
                        @click="addRemarks(item)"
                      >
                        <v-icon small dark>comment</v-icon>
                      </v-btn>
                    </template>
                    <span>Add Remarks</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="issueDateDialog" persistent max-width="290px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Issue Date</span>
        </v-card-title>
        <v-card-text class="pb-1">
          <v-form
            ref="form"
            @submit.prevent="save"
            @keydown.native="form.errors.clear($event.target.name)"
            @keyup.enter="save"
            lazy-validation
          >
            <v-text-field
              label="From Date"
              v-model="issueDate"
              prepend-icon="event"
              :mask="'####-##-##'"
              :rules="dateValidation"
              placeholder="YYYY-MM-DD"
              :height="25"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="issueDateDialog = false"
            >Close</v-btn
          >
          <v-btn
            v-if="singlePrintEnable"
            color="success"
            outlined
            @click="printSingle"
            >Print Single</v-btn
          >
          <v-btn v-else color="success" outlined @click="printBulk"
            >Print Bulk</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="remarksDialog" persistent max-width="590px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Add Remarks</span>
        </v-card-title>
        <v-card-text class="pb-1">
          <v-textarea
            :label="
              'Remarks for ' +
                remarkFor.name +
                ', GPA Scored :' +
                (remarkFor.gpa + ` ${remarkFor.status}` || ' -')
            "
            v-model="remarks"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="remarksDialog = false"
            >Close</v-btn
          >
          <v-btn color="success" outlined @click="submitRemarks">Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import NavFactory from "../../../../library/NavFactory";
import {
  passDataToExternalDomain,
  validateDate,
} from "../../../../library/helpers";

const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    selected: [],
    selectedStudentIds: [],
    sendSMSModal: false,
    isVerified: false,
    isCustomSMS: false,
    customSMSText: "",
    templateType: "default",
    smsLimit: 20,
    direction: "top",
    printType: "terminal",
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    top: false,
    right: true,
    bottom: true,
    issueDate: nd.format("YYYY-MM-DD"),
    today: nd.format("YYYY-MM-DD"),
    status: 0,
    academic: [],
    verfied: [],
    left: false,
    transition: "slide-y-reverse-transition",
    //////////////
    dateValidation: [(v) => validateDate(v) || "Invalid Date Format"],
    examLoading: false,
    gradeLoading: false,
    subjectLoading: false,
    issueDateDialog: false,
    singlePrintEnable: false,
    singlePrintEnrollId: "",
    singlePrintType: "",
    form: new Form(
      {
        grade_id: "",
        subject_id: "",
        section_id: "",
        exam_id: "",
        students: [],
        is_verify: 0,
      },
      "/api/report/exam/marksheet"
    ),
    search: null,
    pagination: {
      rowsPerPage: 100,
    },
    markSheetType: "grading",
    rowsPerPageItems: [5, 10, 25, 50, 75],
    is_verify: 0,
    dataVerfiy: false,
    subject: "",
    subjects: [],
    section: "",
    sections: [],
    exam: "",
    exams: [],
    grade: "",
    grades: [],
    subject_marks: {},
    remarksDialog: false,
    enroll_id: "",
    remarks: "",
    remarkFor: {},
    myRoleSlug: "",
    headers: [
      {
        text: "Roll",
        align: "left",
        value: "roll",
        width: 10,
        sortable: false,
      },
      {
        text: "Name",
        align: "left",
        value: "name",
        width: 200,
        sortable: false,
      },
      {
        text: "Remarks",
        align: "left",
        value: "remarks",
        sortable: false,
        width: 450,
      },
      // {text: 'GPA', align: 'left', value: 'gpa', sortable: false},
      {
        text: "Action",
        align: "right",
        value: "theory",
        sortable: false,
        width: 200,
      },
    ],
    disablePdf: false,
  }),
  computed: {
    ...mapState(["batch"]),
    subjectName() {
      let subjectName = "";
      if (this.subject) {
        let $this = this;
        this.subjects.map(function(subject) {
          if (subject.value === $this.subject) subjectName = subject.text;
        });
      }
      return subjectName;
    },
    absentStudentsCount() {
      let count = 0;
      this.form.items.data.map((item) => {
        if (item.theory === "Absent" || item.practical === "Absent") {
          count++;
        }
      });
      return count;
    },
    selectedExam() {
      let selectedExam = {};
      let $this = this;
      this.exams.map(function(exam) {
        if (exam.value === $this.exam) {
          selectedExam = exam;
        }
      });
      return selectedExam;
    },
    selectedGrade() {
      let selectedGrade = {};
      let $this = this;
      this.grades.map(function(grade) {
        if (grade.value === $this.grade) {
          selectedGrade = grade;
        }
      });
      return selectedGrade;
    },
    selectedSection() {
      let selectedSection = {};
      let $this = this;
      this.sections.map(function(section) {
        if (section.value === $this.section) {
          selectedSection = section;
        }
      });
      return selectedSection;
    },
  },
  mounted() {
    this.getExams();
    const student = JSON.parse(this.$storage.get("_s_ch"));
    this.grade = student.grade_id;
    this.section = student.section_id;
    this.enroll_id = student.enroll_id;

    this.myRoleSlug = this.$storage.get("_role");
  },
  watch: {
    batch: function(value) {
      this.get();
    },
    "guardian.selectedChild": function(value) {
      this.grade = value.grade_id;
      this.section = value.section_id;
    },
    grade: function(v) {
      this.section = "";
      this.subject = "";
      this.form.items.data = [];
      if (v) {
        this.getSections();
        this.getGradeRatingStatus();
      }
    },
    exam: function() {
      this.form.items.data = [];
      this.get();
    },
  },

  methods: {
    passDataToExternalDomain,
    isECA: NavFactory.isEca,

    get(params) {
      if (this.exam && this.enroll_id) {
        let query = [null, undefined].includes(params)
          ? this.queryString(
              "&examId=" +
                this.exam +
                "&gradeId=" +
                this.grade +
                "&enrollId=" +
                this.enroll_id
            )
          : params;

        this.form.get(null, query).then(({ data }) => {
          // this.verfied = data.verify.subject;
          // this.academic = data.academic.subject;
          // if (data.verify.status === 1 && data.academic.status === 1) this.status = 1;
          // else this.status = 0;
        });
      }
    },

    getExams() {
      this.examLoading = true;
      this.$rest
        .get("/api/exam")
        .then(({ data }) => {
          this.exams = data.data.map((item) => {
            return { value: item.id, text: item.name, is_final: item.is_final };
          });
        })
        .finally(() => {
          this.examLoading = false;
        });
    },
    getSections() {
      this.$rest
        .get("/api/section?batch=" + this.batch.id + "&grade=" + this.grade)
        .then(({ data }) => {
          this.sections = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },
    printSingleModal(enrollId, printType) {
      this.issueDateDialog = true;
      this.singlePrintEnable = true;
      this.singlePrintEnrollId = enrollId;
      this.singlePrintType = printType;
    },
    printSingle(enrollId, printType = "terminal") {
      this.$rest.get("/api/print/mark-sheet").then(({ data }) => {
        let url =
          data.data.download_url +
          "?examId=" +
          this.exam +
          "&sectionId=" +
          this.section +
          "&enrollId=" +
          this.singlePrintEnrollId +
          "&issueDate=" +
          this.issueDate.dateForm() +
          "&print_type=" +
          this.singlePrintType;

        if (this.markSheetType === "rating") url += "&marksheet_type=rating";

        window.open(url);
        this.issueDateDialog = true;
      });
    },
    printBulk() {
      this.$rest.get("/api/print/mark-sheet").then(({ data }) => {
        let url =
          data.data.download_url +
          "?examId=" +
          this.exam +
          "&sectionId=" +
          this.section +
          "&issueDate=" +
          this.issueDate.dateForm() +
          "&print_type=" +
          this.printType;
        if (this.markSheetType === "rating") url += "&marksheet_type=rating";
        window.open(url);
        this.issueDateDialog = true;
      });
    },

    printLedger(flag) {
      this.$rest.get("/api/download/marks-ledger").then(({ data }) => {
        let url =
          data.data.download_url +
          "?examId=" +
          this.exam +
          "&sectionId=" +
          this.section +
          "&gradeId=" +
          this.grade;
        if (flag) {
          url += "&download=true";
        }
        url += "&print_type=" + this.printType;

        window.open(url);
      });
    },
    addRemarks(item) {
      this.remarksDialog = true;
      this.remarkFor = item;
      this.remarks = item.remark ? item.remark.remarks : "";
      this.enroll_id = item.enroll_id;
    },
    submitRemarks() {
      this.$rest
        .post("/api/exam/student-remark", {
          enroll_id: this.enroll_id,
          section_id: this.section,
          grade_id: this.grade,
          exam_id: this.exam,
          remarks: this.remarks,
        })
        .then((data) => {
          this.$events.fire("notification", {
            message: data.data.message,
            status: "success",
          });
          this.remarksDialog = false;
          this.get();
        })
        .catch((e) => {
          this.remarksDialog = false;
          this.$events.fire("notificaiton", {
            message: e.response.data.message,
            status: "error",
          });
        });
    },

    getGradeRatingStatus() {
      this.$rest.get("api/marksheet-type?gradeId=" + this.grade).then((res) => {
        this.markSheetType = res.data.marksheet_type;
      });
    },
    printECA(enrollID) {
      this.$rest.get("/api/print/eca-achievement").then(({ data }) => {
        let url =
          data.data.download_url +
          "?examId=" +
          this.exam +
          "&sectionId=" +
          this.section +
          "&enrollId=" +
          enrollID;
        passDataToExternalDomain("reporting");
        if (this.disablePdf === false) {
          this.disablePdf = true;
          let $this = this;
          setTimeout(function() {
            window.open(url);
            $this.disablePdf = false;
          }, 500);
        }

        // window.open(url)
      });
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.form.items.data.slice(0, this.smsLimit);
    },

    selectAllStudent() {
      if (this.selectedStudentIds.length === 0) {
        this.toggleAll();
      }
      this.selectedStudentIds = [];
      this.form.items.data.map((res) => {
        this.selectedStudentIds.push(res.id);
      });
    },
  },
};
</script>
<style lang="scss">
.marks_entry {
  input[disabled] {
    background-color: #eee;
    color: #ccc;
    cursor: not-allowed;
  }

  .mark_entry_input {
    border: 1px solid #ccc;
    font-weight: bold;
    border-radius: 3px;
    width: 60px;
    height: 25px;
    text-align: center;
  }

  .marks_entry_input_container {
    display: flex;
    align-items: center;
    justify-content: center;

    .v-input__slot {
      margin-bottom: 0 !important;
    }

    .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
      margin-bottom: 0 !important;
    }

    .v-input__control {
      display: -webkit-box !important;
      -webkit-box-orient: horizontal !important;
    }

    .v-input {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      display: -webkit-box !important;
      display: -ms-flexbox;
      /* display: flex; */
      /* -webkit-box-flex: 1; */
      -ms-flex: 1 1 auto;
      flex: none;
      font-size: 16px;
      text-align: left;
    }
  }

  input:focus {
    outline: none;
  }
}

#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}

.subject-error {
  background: #f95050;
  color: white;
  padding: 0 5px;
  /*padding-right: 5px;*/
  border-radius: 2px;
  margin-left: 5px;
}
</style>
